<template>
  <div class="chat-window">
    <div class="chat-title">
      收件箱-展开邮件
    </div>
    <div class="chat-container">
      <div class="top-button">
        <el-button round
                   size="small"
                   icon="el-icon-d-arrow-left"
                   @click="$router.back()"></el-button>
        <el-button v-if="model.status != 1"
                   round
                   size="small"
                   @click="handleReply">回复</el-button>
        <el-button v-if="model.status != 1"
                   round
                   size="small"
                   @click="handleTranspond">转发</el-button>
        <el-button round
                   size="small"
                   @click="handleDelete">删除</el-button>
      </div>
      <div class="inbox-content">
        <div class="content-title">{{model.title}}</div>
        <div class="inbox-info">
          <p>发件人：{{model.addresser}}</p>
          <p>收件人：{{model.recipients}}</p>
          <p>时间：{{model.time}}</p>
        </div>
        <el-input class="content-detail"
                  type="textarea"
                  :rows="8"
                  resize="none">
        </el-input>
        <div v-if="model.status != 1"
             class="accessory">
          <div class="accessory-title">相关附件</div>
          <div class="accessory-item">
            <img src="@/assets/images/ic_fujian1@3x.png">
            <p>附件名称</p>
            <p>预览</p>
            <p>下载</p>
          </div>
          <div class="accessory-item">
            <img src="@/assets/images/ic_fujian3@3x.png">
            <p>附件名称</p>
            <p>预览</p>
            <p>下载</p>
          </div>
        </div>
      </div>
      <div class="submit">
        <div class="submit-button">
          <el-button round
                     size="small"
                     icon="el-icon-d-arrow-left"
                     @click="$router.back()"></el-button>
          <el-button v-if="model.status != 1"
                     round
                     size="small"
                     @click="handleReply">回复</el-button>
          <el-button v-if="model.status != 1"
                     round
                     size="small"
                     @click="handleTranspond">转发</el-button>
          <el-button round
                     size="small"
                     @click="handleDelete">删除</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  // 收件详情信息
  name: 'InboxDetailInfo',
  components: {

  },
  data () {
    return {
      model: {},
    }
  },
  created () {
    this.model = this.$route.query.params
  },
  methods: {
    // 回复 
    handleReply () {
      this.$router.push({ path: 'InboxDetailReply' })
    },
    // 转发
    handleTranspond () {
      this.$router.push({ path: 'InboxDetailReply', query: { type: 1 } })
    },
    // 删除
    handleDelete () {

    },
  }
}
</script>

<style scoped>
.chat-window {
  min-width: 506px;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-left: 1px solid #dcdfe6;
}
.chat-window .chat-title {
  display: flex;
  align-items: center;
  height: 60px;
  font-size: 18px;
  color: #313233;
  letter-spacing: 2px;
  padding: 0 20px;
  background: #ffffff;
  box-sizing: border-box;
  border-bottom: 1px solid #dcdfe6;
}
.chat-window .chat-container {
  height: 540px;
  width: 100%;
  /* padding-top: 20px; */
  box-sizing: border-box;
  border-bottom: 1px solid #dcdfe6;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* overflow-x: hidden;
  overflow-y: auto; */
  /* position: relative; */
}
.top-button {
  height: 47px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px;
  border-bottom: 1px solid #dcdfe6;
}
.top-button button {
  width: 56px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.top-button button:nth-child(1) {
  width: 48px;
}
.inbox-content {
  /* height: 100%; */
  height: 424px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.inbox-content .content-title {
  font-size: 14px;
  color: #313233;
  margin-bottom: 20px;
}
.inbox-content .inbox-info p {
  font-size: 12px;
  color: #7b7d80;
}
.inbox-content .inbox-info p:nth-child(2) {
  margin: 10px 0;
}
.inbox-content .content-detail {
  padding: 20px 0;
}
.inbox-content .accessory {
  display: flex;
  flex-direction: column;
}
.accessory .accessory-title {
  font-size: 12px;
  color: #313233;
  margin-bottom: 20px;
}
.accessory .accessory-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  margin-bottom: 10px;
}
.accessory .accessory-item img {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}
.accessory-item p:nth-child(3) {
  color: #ff6632;
  margin: 0 20px;
  cursor: pointer;
}
.accessory-item p:nth-child(4) {
  color: #4db4d4;
  cursor: pointer;
}
.submit {
  width: 100%;
  height: 68px;
  padding-left: 10px;
  padding-right: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* position: absolute;
  bottom: 0; */
  border-top: 1px solid #dcdfe6;
}
.submit-button {
  display: flex;
  flex-direction: row;
}
.submit button {
  width: 56px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.submit button:nth-child(1) {
  width: 48px;
}
</style>